import Cookies from 'js-cookie'
import {getQueryString} from "@/utils/utils";

const TokenKey = 'vue_admin_template_token'

export function getToken() {
  return Cookies.get(TokenKey) || getQueryString('token')
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
