
import axios from 'axios'
import store from '@/store'
import { Toast } from 'vant'
import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
      // do something before request is sent

      if (store.getters.token) {
        // let each request carry token
        // ['X-Token'] is a custom headers key
        // please modify it according to the actual situation
        config.headers['token'] = getToken()
      }
      return config
    },
    error => {
      // do something with request error
      console.log(error) // for debug
      return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
      const res = response.data
      // if the custom code is not 1, it is judged as an error.
      if (res.code !== 200) {
        var duration = 5
        if (res.code === 402) {
          duration = 1
        }
        Toast({
          message: res.message || 'Error',
          duration: duration * 1000,
          onClose: function() {
            if (res.code === 402) {
              store.dispatch('user/logout').then(() => {
                location.reload()
              })
            }
          }
        })
        return Promise.reject(new Error(res.message || 'Error'))
      } else {
        return res
      }
    },
    error => {
      console.log('err' + error) // for debug
      Toast({
        message: error.message,
        duration: 5 * 1000
      })
      return Promise.reject(error)
    }
)

export default service
