import Vue from 'vue'
import App from './App.vue'
import '@/assets/dpr.js'
import '@/assets/fastclick.js'
import '@/assets/iconfont/iconfont.css'
import router from './router'
import store from './store'
import './permission'
import './utils/directives.js'
import Vant from 'vant';
import 'vant/lib/index.css';
import request from '@/utils/request'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);


Vue.use(Vant);
Vue.prototype.$request = request
Vue.prototype.$post = (url, data) => request.post(url, data, {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  transformRequest: [data => Object.keys(data).map(it => encodeURIComponent(it) + '=' + encodeURIComponent(data[it])).join('&')]
})
Vue.prototype.$get = (url, data) => request({ url, params: data })

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
