export const formatDate = (d, fmt) => {
	let date;
	if(d == null || (d + '').length == 14) {
		return null;
	} else if(d.length == 28) {
		let dateee = new Date(d).toJSON();
		date = new Date(new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, ''));
	} else if((d + '').length == 13 || (d + '').length == 21 || (d + '').length == 19) {
		date = new Date(d);
	} else {
		date = d;
	}
	let o = {
		'M+': date.getMonth() + 1, // 月份
		'd+': date.getDate(), // 日
		'h+': date.getHours(), // 小时
		'H+': date.getHours(), // 小时
		'm+': date.getMinutes(), // 分
		's+': date.getSeconds(), // 秒
		'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
		'S': date.getMilliseconds() // 毫秒
	};
	if(/(y+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
	}
	for(let k in o) {
		if(new RegExp('(' + k + ')').test(fmt)) {
			fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
		}
	}
	return fmt;
};

export const getNowTime = () => {
	//获取当前时间
	let myDate = new Date()
	let wk = myDate.getDay()
	let yy = String(myDate.getFullYear())
	let mm = myDate.getMonth() + 1
	let dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate())
	let hou = String(myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours())
	let min = String(myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes())
	//let sec = String(myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds())
	let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
	let week = weeks[wk]
	return yy + '/' + mm + '/' + dd + " " + week + " " + hou + ':' + min
}

export const getQueryString = (name) => {
	const reg = `(^|&)${name}=([^&]*)(&|$)`
	const r = window.location.search.substr(1).match(reg)
	if(r != null) return decodeURIComponent(r[2]);
	return null
}