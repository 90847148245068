<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { setToken, getToken } from '@/utils/auth'
import {getQueryString} from '@/utils/utils'

export default {
  name: 'App',
  created(){
    var token = getQueryString('token')
    console.log(token)
    if(token && !getToken()) {
      setToken(token)
      location.reload()
    }
  },
  methods: {
  }
}
</script>
<style>
html,body,#app{
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.icon{
  font-family: 'iconfont';
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>